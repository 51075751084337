<template>
    <div class="paySelect">
        <Header backType="blank" :back="true" :backEvent="true" @backEvent="back">
            <span slot="headText">支付方式</span>
        </Header>
        <div>
            <div class="scan" @click="openScan">
                <div class="code">
                    <Svgs color="#fff" name="iconerweima"></Svgs>
                </div>我扫商户
            </div>
            <div @click="goPay">
                <div class="code2">
                    <Svgs color="#007AFF" name="iconerweima"></Svgs>
                </div>商户扫我
            </div>
        </div>
        <MessageBox ref="mesBox" type="tips" :messageTxt="msg" @onOk="onOk"></MessageBox>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import {
	SmartStorage
} from 'smart-core-util'
let wx = window.wx
export default {
    data() {
        return {
            shopCode: "",
            payhubSingnal: null,
            msg: ""
        };
    },
    computed: {
        ...mapGetters(["expenseId","event"])
    },
    async created(){
    },
    methods: {
        ...mapMutations(["FIN3_RESULT"]),
        back() {
            this.$router.push({ path: "/orderSettle" });
        },
        GenerateWechatJsSDKParams(sid){
            let params = {
                sid: sid,
                currUrl: window.document.location.href
            }
            if(window.__wxjs_environment === "miniprogram" && this.event.tenant_code == 'roche'){
                params.currUrl = 'https://' + window.document.location.host + '/?proposalId='+`${this.event.proposalId}`+'&type=tenant&sessionId='+`${SmartStorage.get("sessionId")}`+'&rnd=' + `${SmartStorage.get("rnd")}`
            }
            return new Promise((resolve,reject) => {
                miceService.GenerateWechatJsSDKParams(params).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        async wxOpenScan(){
            let appId = 'ww2a8578015f02cdd3'
            let sid = '2164'
            if(['会引擎-石药','会引擎-恩必普'].includes(this.event.tenant_name)){
                appId = 'ww7beb2b180d97789b',
                sid = '2160'
            }
            if(this.event.tenant_name == '会引擎-huarun'){
                appId = 'wx859cba36dedb1a67'
                sid = '2163'
            }
            if (this.event.tenant_name == "会引擎-灵北") {
                appId = "wp2HVeDAAAD7KJBgbcl83wmoD-G3b0PA";
                sid = "2167";
            }
            if (this.event.tenant_code == "roche") {
                appId = "wx00a65cbbe46b0fb2";
                sid = "1102";
            }
            let _this = this
            let wvRes = await this.GenerateWechatJsSDKParams(sid)
            wx.config({
                beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                // appId: 'ww8e54ffd2ad46e81e', // 必填，企业微信的corpIDww7beb2b180d97789b
                appId: appId, // 必填，企业微信的corpIDww7beb2b180d97789b
                timestamp: wvRes.timestamp, // 必填，生成签名的时间戳
                nonceStr: wvRes.nonceStr, // 必填，生成签名的随机串
                signature: wvRes.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                jsApiList: ['scanQRCode',] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });
            wx.error(function (res) {
                let url = 'https://' + window.document.location.host + '/?proposalId='+`${this.event.proposalId}`+'&type=tenant&sessionId='+`${SmartStorage.get("sessionId")}`+'&rnd=' + `${SmartStorage.get("rnd")}`
                alert('config失败');

                alert(JSON.stringify(res));
                alert(url);
            });
            wx.ready(function(){
                wx.scanQRCode({
                    desc: 'scanQRCode desc',
                    needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
                    success: function(res) {
                        console.log('扫描结果',res);
                        // 回调
                        var result = res.resultStr;//当needResult为1时返回处理结果
                        //上传扫描的二维码
                        _this.shopCode = result;
                        _this.postCode();
                    },
                    error: function(res) {
                        alert('调用摄像头失败',res)
                        if (res.errMsg.indexOf('function_not_exist') > 0) {
                            alert('版本过低请升级')
                        }
                    }
                });
            });
        },
        // 我扫商户
        async openScan() {
            var ua = navigator.userAgent.toLowerCase();
            if(/micromessenger/i.test(ua)){
                this.wxOpenScan()
                return
            }
            var _this = this;

            if (window.flutter_inappwebview) {
                let data = await window.flutter_inappwebview.callHandler("App.openQrScanner", '');
                if(data == '' || data == 'user_cancel'){return}
                if(data){
                    //上传扫描的二维码
                    _this.shopCode = data;
                    _this.postCode();
                }
            } else {
                var options = {
                    loadUrl: "",
                    method: "SMBarcodeScaner.scan",
                    postData: "",
                    callback: function(data) {
                        if (!data) return;
                        if(typeof(data) != 'object') {
                            data = JSON.parse(_this.replaceObj(data));
                        }
                        if (data.type == "QR_CODE" && data.text) {
                            //上传扫描的二维码
                            _this.shopCode = data.text;
                            _this.postCode();
                        } else {
                            _this.toast("二维码扫描失败 " + data.text);
                        }
                    }
                };
                this.iJsBridge.call(options);
            }
        },
        replaceObj(obj) {
            let result = obj.replace(/\t/g, "");
            return result;
        },
        postCode() {
            let input = {
                payOption: "支付宝",
                paySelect: "我扫商户",
                payChannel: "1",
                expenseId: this.expenseId,
                shopQRCode: this.shopCode
            };
            miceService.sendPayRequest(input).then(res => {
                if (!res || !res.result.status) {
                    this.toast((res.result || { msg: "发起支付请求失败" }).msg);
                    return;
                }
            });
            this.openSignal();
        },
        // 商户扫我
        goPay() {
            this.$router.push({ path: "/payQrcode" });
        },
        //打开支付2.0信号
        openSignal() {
            var _this = this;
            // 重置 Fin3Result
            this["FIN3_RESULT"](null);
            this.payhubSingnal = new this.iSignalr({
                hubName: "payHub",
                queryParams: "group=" + this.expenseId,
                listeners: [
                    {
                        methodName: "rejected",
                        method: function(data) {
                            _this.on_rejected(data);
                        }
                    },
                    {
                        methodName: "completed",
                        method: function(data) {
                            _this.on_completed(data);
                        }
                    }
                ],
                host: SmartStorage.get("Uris").Uris.signalr
            });
            this.payhubSingnal.install().then(() => {
                this.$router.push({
                    path: "/uploadInvoice"
                });
            });
        },
        on_rejected: function(data) {
            this["FIN3_RESULT"](null);
            this.closeInstallHub();
            this.msg = data.message;
            this.$refs.mesBox.openPop();
        },
        onOk() {
            this.$router.push({
                path: "/orderList"
            });
        },
        on_completed: function(data) {
            this["FIN3_RESULT"](null);
            this["FIN3_RESULT"](data);
            this.closeInstallHub();
            setTimeout(() => {
                if (data.message) {
                    data.message = null;
                }
                this["FIN3_RESULT"](data);
            }, 3000);
        },
        closeInstallHub: function() {
            if (this.payhubSingnal) {
                this.payhubSingnal.uninstall();
            }
        }
    }
};
</script>
<style lang="scss">

.paySelect {
    background: #f8f8f8;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 0.14rem;

    .scan {
        width: 80%;
        margin: 0 auto;
        margin-top: 1rem;
        padding: 0.2rem;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 0.13rem;
        box-shadow: 1px 1px 0.15rem #dedede;
        text-align: center;
        margin-bottom: 0.5rem;

        .code {
            width: 0.56rem;
            height: 0.56rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background: var(--themeColor);
            margin-bottom: 0.11rem;
        }
    }

    .code2 {
        width: 0.56rem;
        height: 0.56rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 0.1rem;
        border-color: var(--themeColor)
    }

    .code,
    .code2 {
        .icon {
            width: 0.26rem;
            height: 0.26rem;
        }
    }
}
</style>
